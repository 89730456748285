import apiClient from '@/services/axios'
import moment from 'moment'

export const uploadConsent = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/consent', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const uploadLabImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/lab-result', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const uploadLabResult = async (testLabId, linkUrl) => {
  try {
    const res = await apiClient.post('/testLabEvidence', {
      testLabId,
      urls: [linkUrl.data],
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const createLabTest = async (testLabTypeId, patientId) => {
  try {
    const res = await apiClient.post('/testLab/self-test', {
      testLabTypeId,
      patientId,
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const uploadResepImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/prescription', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const uploadWeightImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/enhertu', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const uploadResepResult = async (programId, linkUrl, weightUrl) => {
  try {
    const payload = {
      url: linkUrl.data,
      programId,
      weightUrl: weightUrl ? weightUrl.data : '',
    }
    const res = await apiClient.post('/programEvidence', payload)
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const getListDoctor = async id => {
  const res = await apiClient.get('/doctor')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getListProgramType = async id => {
  const res = await apiClient.get('/programType')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getListProgramTypeById = async id => {
  const res = await apiClient.get('/programType/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getPatientProgram = async code => {
  const res = await apiClient.get('/patient/code/' + code)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getPatientMaster = async id => {
  const res = await apiClient.get('/npsMaster')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getPatientNps = async id => {
  const res = await apiClient.get('/npsPatient/patient/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const createPatientNps = async (payload, patientId, isSkip) => {
  const res = await apiClient.post('/npsPatient', { responses: payload, patientId, isSkip })
  if (res) {
    return res.dat
  } else {
    return []
  }
}
export const createProgram = async (patientId, doctorId, programTypeId, consent, category) => {
  const res = await apiClient.post('/program', {
    patientId,
    doctorId,
    programTypeId,
    consent,
    category: category === 'aurora' ? category : null,
  })
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const updateTestLab = async (programId, testLabId) => {
  const res = await apiClient.post('/program/updateTestLab', {
    id: programId,
    testLabId,
  })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const confirmProgram = async (programId, confirmationCode) => {
  const res = await apiClient.post('/program/confirm', {
    id: programId,
    confirmationCode,
  })
  return res.data
}

export const testLabTypeList = async () => {
  const res = await apiClient.get('/testLabType')
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const getSurvey = async programTypeId => {
  const res = await apiClient.get('/survey/question', { params: { programTypeId } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const submitSurvey = async (answerId, patientId) => {
  const res = await apiClient.post('/survey/submit', { patientId, answerId })
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const uploadInsuranceImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/certificate', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const uploadInsuranceResult = async (testLabId, linkUrl) => {
  try {
    const res = await apiClient.post('/insurance', {
      testLabId,
      urls: [linkUrl.data],
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const updateProgramInsurance = async (id, insuranceId) => {
  try {
    const res = await apiClient.post('/program/updateInsurance', {
      id,
      insuranceId,
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const uploadKwitansiImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/redemption', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const uploadKwitansiResult = async (programId, linkUrl) => {
  try {
    const payload = {
      url: [linkUrl.data],
      programId,
    }
    const res = await apiClient.post('/medicalRedemption', payload)
    // await apiClient.post('/medicalRedemption/validate' + `/${res.data[0].id}`)
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const getSurveyResult = async patientId => {
  try {
    const res = await apiClient.get('/survey/submit', { params: { patientId } })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const checkDailyReminder = async (hour, minute, patientId, programTypeName) => {
  try {
    const res = await apiClient.post('/pillsTaken/add', {
      hour,
      minute,
      patientId,
      programTypeName,
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const continueProgram = async programId => {
  try {
    const res = await apiClient.post('/program/continue', {
      id: programId,
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const terminateProgram = async (programId, message) => {
  try {
    const res = await apiClient.post('/program/terminate', {
      id: programId,
      message,
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}
export const getTerminateReason = async () => {
  try {
    const res = await apiClient.get('/report/program/terminate')
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const getHistoryDrugsReminder = async (patientId, drugsTakenDate, medicines) => {
  try {
    const drugDate = moment(new Date(drugsTakenDate))
    var diff = moment().diff(drugDate, 'day')
    console.log(diff, '<<<<CEk')
    const res = await apiClient.get('/pillsTaken', {
      params: {
        patientId,
        days: diff,
        drugsTakenDate,
        medicines,
        enroll: drugsTakenDate,
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const updateProfile = async (
  dateOfBirth,
  email,
  fullname,
  gender,
  idNumber,
  idPicture,
  representativeName,
  representativePhone,
  representativeRelationship,
  selfiePicture,
  status,
  patientId,
) => {
  try {
    const res = await apiClient.patch(`/patient/${patientId}`, {
      dateOfBirth,
      email,
      fullname,
      gender,
      idNumber,
      idPicture,
      representativeName,
      representativePhone,
      representativeRelationship,
      selfiePicture,
      status,
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const updatePassword = async (oldPassword, newPassword) => {
  try {
    const res = await apiClient.post('/auth/change-password', {
      newPassword,
      oldPassword,
      role: 'patient',
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const uploadKtpImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/id', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const uploadSelfieImage = async (name, file) => {
  const formData = new FormData()
  formData.append('name', name)
  formData.append('upload', file)

  try {
    const res = await apiClient.post('/upload/selfie', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res
  } catch (error) {
    console.error('Upload failed:', error)
    return []
  }
}

export const getListNews = async () => {
  const res = await apiClient.get('/news')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getListNewsByProgram = async programType => {
  const res = await apiClient.get(`/news/category/program?tag=${programType}`)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getAllTagNews = async () => {
  const res = await apiClient.get('/news/category/getAllTags')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getListNewsById = async id => {
  const res = await apiClient.get('/news/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getVideo = async id => {
  const res = await apiClient.get('/staging/videoVertical')
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getHelp = async id => {
  const res = await apiClient.get('/help')
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const getListNotification = async id => {
  const res = await apiClient.get('/notification/' + id)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const sendEmailVerification = async email => {
  const res = await apiClient.get('/patient/account/validatemail/' + email)
  if (res) {
    return res.data
  } else {
    return []
  }
}

<template>
  <div class="container">
    <a-button
      @click="goDashboard"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;"
      ><LeftOutlined /> Back</a-button
    >
    <div class="notification-list">
      <h3 class="title">Daftar Notifikasi</h3>
      <div v-for="notification in notifications" :key="notification.id" class="notification-item">
        <div class="notification-title">{{ notification.message }}</div>
        <div class="notification-time">{{ notification.createdAt }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getPatientProgram, getListNotification } from '../../services/axios/pulih'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  InfoCircleOutlined,
  HomeOutlined,
  ContactsOutlined,
  ExclamationCircleTwoTone,
  LeftOutlined,
} from '@ant-design/icons-vue'
export default {
  name: 'NotificationList',
  components: { LeftOutlined },
  setup() {
    const notifications = ref([])
    const formatDate = isoDate => {
      const date = new Date(isoDate)
      return new Intl.DateTimeFormat('id-ID', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }).format(date)
    }
    const fetchNotifications = async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const response = await getPatientProgram(userData.user.code)
      const res = await getListNotification(response.id)
      // Map hasil notifikasi dan format tanggal
      notifications.value = res.map(notification => ({
        ...notification,
        createdAt: formatDate(notification.createdAt),
      }))
      console.log(notifications.value, 'formatted notifications')
    }
    const router = useRouter()

    const goDashboard = () => {
      router.push(`/dashboard`)
    }
    onMounted(() => {
      fetchNotifications()
    })

    return {
      goDashboard,
      notifications,
    }
  },
}
</script>
<style scoped>
.notification-list {
  /* max-width: 600px; */
  margin: 20px auto;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: start;
}

.notification-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-title {
  font-size: 16px;
  font-weight: 500;
  width: 400px;
}

.notification-time {
  font-size: 14px;
  color: #888;
}

@media (max-width: 425px) {
  .notification-title {
    width: 100%;
  }
  .notification-item {
    flex-direction: column;
    align-items: start;
  }
}
</style>
